import React ,{ Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'
import config from '../../constant';
import { NavLink } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Ratio from 'react-bootstrap/Ratio';
import {Fade} from 'react-awesome-reveal';
import OwlCarousel from 'react-owl-carousel';
 
class PartnerSlider extends Component {

    
        render() {



            const options = {
                margin: 30,
                responsiveClass: true,
                nav: false,
                dots: true,
                autoplay: true,
                // navText: ['<img src="assets/images/hotel/Left-Arrow.png">', '<img src="assets/images/hotel/Right-Arrow.png">'],
                smartSpeed: 1000,
                responsive: {
                    0: {
                        items: 2,
                    },
                    400: {
                        items: 2,
                    },
                    600: {
                        items: 2,
                    },
                    700: {
                        items: 4,
                    },
                    1000: {
                        items: 6,
            
                    }
                },
            };
    return(
        <>
   <Container className='clients py-5 text-center'>
            <Row>
                <Col>
                <h1 className=" pb-3  mt-0  text-left">Our Partner</h1>

                </Col>
                </Row>

                <Row className='pt-4'>
{/*                     
                    <Col> <Fade direction='up'  delay="40" triggerOnce="true"><Image src={config.ImageUrl+"Client-icon-1.png"} fluid alt={config.SITE_NAME}></Image></Fade>
                     </Col>
                    <Col><Fade direction='up'  delay="80" triggerOnce="true"> <Image src={config.ImageUrl+"Client-icon-2.png"} fluid alt={config.SITE_NAME}></Image></Fade>
                     </Col>
                    <Col> <Fade direction='up'  delay="120" triggerOnce="true"><Image src={config.ImageUrl+"Client-icon-3.png"} fluid alt={config.SITE_NAME}></Image>
                     </Fade> </Col>
                    <Col> <Fade direction='up'  delay="160" triggerOnce="true"><Image src={config.ImageUrl+"Client-icon-4.png"} fluid alt={config.SITE_NAME}></Image>
                     </Fade> </Col>
                    <Col> <Fade direction='up'  delay="200" triggerOnce="true"><Image src={config.ImageUrl+"Client-icon-5.png"} fluid alt={config.SITE_NAME}></Image>
                     </Fade> </Col> */}



                     <OwlCarousel className='owl-theme'  {...options}>
        
                     {[...Array(12)].map((e, i) => { 
                
                return <Fade direction='up'  delay={i  *  40 } triggerOnce="true">
          
    <div className='item'  key={i + 1}>

    <Image src={config.ImageUrl+"/Partner/"+ `${i+1 }`+".jpg" } fluid alt={config.SITE_NAME}></Image>
    
{/* <div className="productDiv" style={{ 
      backgroundImage: `url( ${val.imgUrl} )` 
    }} >
					        
				         </div>                */}
                         </div>
                         </Fade>
                          })}

</OwlCarousel>
                </Row>
                </Container>
 
        </>

    );
}
    }
export default PartnerSlider;