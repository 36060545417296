import React, { useState, useEffect,useRef } from "react";
import { ButtonGroup , Button, Container, Col, Row} from "react-bootstrap";
import Carousel from 'react-bootstrap/Carousel';
 import config from "../../constant";
 import {Fade} from 'react-awesome-reveal'
import { NavLink } from "react-router-dom";

 const SliderObj = [
    {
        imgUrl: config.ImageUrl +'Banner3.jpg',
        imgAlt: config.SITE_NAME,
        title: 'Make Your',
        title2: 'Body Shape',
        desc: 'Distinctively provide acces mutfuncto users whereas communicate leveraged services',  
    },
    // {
    //   imgUrl: config.ImageUrl +'Banner-2.jpg',
    //   imgAlt: config.SITE_NAME,
    //   title: 'Integrated Security Solution ',
    //   title2: 'with Better Control',
    //   desc: 'Distinctively provide acces mutfuncto users whereas communicate leveraged services',
    // },
     
     
];

  

function validate(evt) {
  var theEvent = evt || window.event;
  var key = theEvent.keyCode || theEvent.which;
  key = String.fromCharCode( key );
  var regex = /[0-9]|\./;
  if( !regex.test(key) ) {
    theEvent.returnValue = false;
    if(theEvent.preventDefault) theEvent.preventDefault();
  }
}
//============================================
    
 
const Banner = () => {

  const videoEl = useRef(null);

  const attemptPlay = () => {
    videoEl &&
      videoEl.current &&
      videoEl.current.play().catch(error => {
        console.error("Error attempting to play", error);
      });
  };

  useEffect(() => {
    attemptPlay();
  }, []); 
//console.log("PROPS in MODAL", props);

//  const [showLogin, setShowLogin] = useState(false);

   
    return(
      <>
     {/* <div style={{ 
      backgroundImage: `url( ${config.ImageUrl +'Banner.jpg'} )` 
    }}  className="BannerDiv">

           <Container> <div className="bannerText ">
                <h2>Best solution <br></br><span className="yellow">for oil industry</span></h2>
            </div></Container>
        
     </div> */}
{/* 
<video width="100%" height="100%" autoplay="true" muted="" playsinline="" loop="" id="background-video">
  <source src={config.ImageUrl+"videoo.mp4"} type="video/mp4"/>
 </video> */}

 <video
 id="background-video"
          playsInline
          loop
          muted
          alt={config.SITE_NAME}
          src={config.ImageUrl+"PetroleumVideo.webm"}
          ref={videoEl}
        />
    </>
        );
}
   
export default Banner;