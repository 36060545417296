import React, { Component ,useEffect }  from 'react';
import props from 'prop-types';

import Footer from '../components/layout/footer';
import Header from '../components/layout/header';
import AboutHome from '../components/section/about_home';
import CardSec from '../components/section/cards';
import Goal from '../components/section/goal';
import Service from '../components/section/service';
import Slider from '../components/section/slider';
import Owlslider from '../components/section/owlslider';
import AboutUs from '../components/section/about_us';
import BookingForm from '../components/section/booking_form';
import config from '../constant';
import ProductsPage from '../components/section/products';
import Container  from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image'
 import { Table } from 'react-bootstrap';
import SubHeader from '../components/layout/subheader';
import {Fade} from 'react-awesome-reveal';

 class Methanol extends Component {

    componentDidMount(){
        document.title = "Methanol and lpg | " + config.SITE_NAME;
      }

    render(){

    return(
        <div>
        <Header  />   
        <SubHeader  Title='Methanol and lpg' subtitle='' BkImg={config.ImageUrl + "8-LPG-Banner.jpg"} /> 

        <Container className='AboutSec py-3'  >

        
<Row className='py-5'>



    <Col lg="12" className=' '>

    <p> In addition to our petroleum products, we provide Methanol and Liquefied Petroleum Gas (LPG). Methanol serves as a key ingredient in various chemical processes, while LPG is widely used as a clean and efficient fuel source. Methanol, on the other hand, is a versatile and clean-burning fuel widely used in marine applications.</p>
   <p> LPG is a versatile fuel that can power various ship systems, including propulsion, heating, and cooking, offering environmental benefits and operational flexibility. LPG is a mixture of hydrocarbon gases, including propane and butane, that can be used as a fuel for engines and onboard heating systems. Our LPG solutions ensure reliable and environmentally friendly energy sources for marine vessels.</p>
<p>while methanol serves as a clean-burning fuel option. Both options provide reliable energy sources for ships, ensuring smooth operations and reduced environmental impact.
</p>
<p>With our comprehensive range of marine fuel supply and related services, we strive to meet the diverse needs of ships in port and at sea, ensuring optimal performance, efficiency, and environmental sustainability.</p>


       
    </Col>

    
   
</Row>



</Container>
     
        <Footer/>
        </div>
    );
} 
}
export default Methanol;