import React, { Component ,useEffect }  from 'react';
import props from 'prop-types';

import Footer from '../components/layout/footer';
import Header from '../components/layout/header';
import AboutHome from '../components/section/about_home';
import CardSec from '../components/section/cards';
import Goal from '../components/section/goal';
import Service from '../components/section/service';
import Slider from '../components/section/slider';
import Owlslider from '../components/section/owlslider';
import AboutUs from '../components/section/about_us';
import BookingForm from '../components/section/booking_form';
import config from '../constant';
import ProductsPage from '../components/section/products';
import Container  from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image'
 import { Table } from 'react-bootstrap';
import SubHeader from '../components/layout/subheader';
import {Fade} from 'react-awesome-reveal';

 class Base_oils extends Component {

    componentDidMount(){
        document.title = "Base Oils (SN150, SN500, and Bright Stocks) | " + config.SITE_NAME;
      }

    render(){

    return(
        <div>
        <Header  />   
        <SubHeader  Title='Base Oils (SN150, SN500, and Bright Stocks)' subtitle='' BkImg={config.ImageUrl + "5-Base-Oil-Banner.jpg"} /> 

        <Container className='AboutSec py-3'  >

        
<Row className='py-5'>



    <Col lg="12" className=' '>

   <p> We provide base oils such as SN150, SN500, and bright stocks, which are essential components in the production of lubricants and greases. These high-quality base oils offer excellent viscosity and thermal stability, enhancing the performance and longevity of marine machinery.</p>
   <p>Base oils are essential components in the production of lubricants and greases for marine applications. We offer a selection of base oils, including SN150, SN500, and Bright Stocks, to meet the diverse needs of our clients. SN150 and SN500 are highly refined base oils with excellent oxidation and thermal stability, making them suitable for a wide range of lubricant formulations. Bright stocks, on the other hand, have superior viscosity characteristics, making them ideal for applications requiring high viscosity lubricants. Our reliable supply of base oils ensures that ships can operate smoothly and maintain optimal performance.</p>

       
    </Col>

    
   
</Row>



</Container>
     
        <Footer/>
        </div>
    );
} 
}
export default Base_oils;