import React ,{ Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'
import config from '../../constant';
import { NavLink } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Ratio from 'react-bootstrap/Ratio';
import {Fade} from 'react-awesome-reveal';
import Mainmap from './MainMap';

class AboutDiv extends Component {
        render() {

    return(
        <>
    <Container className='AboutSec py-3'  >

        
        <Row className='py-5'>

       

            <Col lg="12" className=' '>
            <h1 className=" pb-3  mt-0  text-left">About {config.SITE_NAME} </h1>

            <p>FLC trading has been setup in Abu Dhabi (UAE) to establish a long term presence in the Middle East in terms of bunkering. We can provide with very short notice, Crude oil light & heavy, CST 100 & 180 & 280 & 380, Nafta, D2-D6, Jet Fuel, Methanol, Gasoline, Gas oil, Fuel Oil. </p>
<p>As a physical supplier, we cover marine fuel supply chain commencing from sourcing of fuel oil from refineries to storage to sales and finally physical distribution by refuelling vessels to ships in port and at sea. </p>
<p>We have guaranteed ISO 8217:2005 quality specification on Physical supplied and all bunker fuel supplied while fully compliant with MARPOL annex VI and MSDS.</p> 
<p>FLC trading is a leading international provider of marine fuel supply and related service solutions to ships in port and at sea. Our experience and our most competitive prices make our company the most reliable partner. All our barges are sire approved and FLC trading keep on following and doing all the regular inspection as per local and international requirement as and when due and required. </p>
<p>Our professional team members are incredible sources of inspiration devoted to providing consummate services and experience. </p>
<p>FLC trading has professional team members who are incredible sources of inspiration devoted to providing consummate services and experience.</p>

               
            </Col>

            {/* <Col lg={{span:4,offset:1 }} >
                <Image src={config.ImageUrl+"About-us-Image.png"} fluid alt={config.SITE_NAME}></Image>
            </Col> */}
           
        </Row>


    
    </Container>

    
    <section className='process-items d-none'>

        <Container className="py-5">
        <h1 className=" pb-3  text-center">Core Values</h1>
        <h4 className=" pb-4 text-center">The CAMline culture and brand are based on core values that guide our daily operations.</h4>

        <Row>
                <Col lg="4">
                    <div className="item">
                            <a href="#">
                            <div className="cardBox2">
                            <Image src={config.ImageUrl+"Leader-Green.png"}  className="img-top1" alt={config.SITE_NAME}/>
                            <Image src={config.ImageUrl+"Leader-White.png"}  className="img-top" alt={config.SITE_NAME} />
                            </div>
                                <h4>Leader</h4>
                                <p>Establishing a path forward for security by setting the highest level of global standards.</p>
                            </a>
                        </div>
                        
                    </Col>

                <Col lg="4">
                        <div className="item">
                            <a href="#">
                            <div className="cardBox2">
                            <Image src={config.ImageUrl+"Teamwork-Green.png"}  className="img-top1" alt={config.SITE_NAME}/>
                            <Image src={config.ImageUrl+"Teamwork-White.png"}  className="img-top" alt={config.SITE_NAME} />
                            </div>                                <h4>Teamwork</h4>
                                <p>The foundation of everything we do. A work ethic that encourages all CAMline staff to go that extra mile.</p>
                            </a>
                        </div>
                    </Col>
                    <Col lg="4">
                        <div className="item">
                            <a href="#">
                            <div className="cardBox2">
                            <Image src={config.ImageUrl+"Continuous-Improvement-Green.png"}  className="img-top1" alt={config.SITE_NAME}/>
                            <Image src={config.ImageUrl+"Continuous-Improvement-White.png"}  className="img-top" alt={config.SITE_NAME} />
                            </div>                                   <h4>Continuous Improvement</h4>
                                <p>CAMline is dedicated to continually enhancing our accomplishments by making things better, smarter, more effective, and more efficient.  </p>
                            </a>
                        </div>
                    </Col>
            </Row>
        </Container>
    </section>

    <Container className='reasonsSec py-5 d-none'>
        <Row>
            <Col lg="12">
            <h1 className=" pb-3  mt-0  text-center">Mission & Vision</h1>
            

            </Col>
            
        </Row>

        <Row className='py-3'>
            <Col lg="6">
                 
                    <Image  src={config.ImageUrl+"mission.png"} fluid alt={ config.SITE_NAME} />
                    <div className='text'>
                     <h4>Mission</h4>
                    <p>Building long term relationship with our customers by creating real values for them through our innovative products and services.</p>
                    </div>
             
            </Col>
            <Col lg="6">
            <Image  src={config.ImageUrl+"vision.png"} fluid alt={ config.SITE_NAME} />
                    <div className='text'>
                     <h4>Vision</h4>
                    <p>Our company vision is to offer exceptional, tailored security solutions at an affordable cost, supported around the clock.</p>
                    </div>
                    </Col>
        </Row>

        
         
    </Container>

    <section className='process-items d-none'>
    <Container className="py-5">
        <h1 className=" pb-3  text-center">Company Journey</h1>
         

        <Row>
                <Col lg="12" className='container2'>

                <ul className="timeline">
    <li>
    <Fade direction='up'  delay="40" triggerOnce="true">
    <Image  src={config.ImageUrl+"2016.png"} fluid alt={ config.SITE_NAME} />
      <h3>2016</h3>
      <p>CAMline Security Solutions Begins Operation.</p>
      </Fade>
    </li>
    <li>
    <Fade direction='up'  delay="80" triggerOnce="true">

    <Image  src={config.ImageUrl+"2017.png"} fluid alt={ config.SITE_NAME} />
<h3>2017</h3>
      <p>Built Business Engagement with Global Organization to represent their solutions in domestic market. </p>
      </Fade>
    </li>
    <li>
    <Fade direction='up'  delay="120" triggerOnce="true">

    <Image  src={config.ImageUrl+"2018.png"} fluid alt={ config.SITE_NAME} />
      <h3>2018</h3>
      <p>Approached big branded clients.</p>
      </Fade>

    </li>
    <li>
    <Fade direction='up'  delay="160" triggerOnce="true">

      <Image  src={config.ImageUrl+"2019.png"} fluid alt={ config.SITE_NAME} />
      <h3>2019</h3>
      <p>Expanded our presence pan India with large technical team with pure security solution.</p>
      </Fade>
    </li>
    <li>
    <Fade direction='up'  delay="200" triggerOnce="true">
      <Image  src={config.ImageUrl+"2020.png"} fluid alt={ config.SITE_NAME} />
      <h3>2020</h3>
      <p>Now we are available pan India with strong team. </p>
      </Fade>
    </li>
    <li>
    <Fade direction='up'  delay="240" triggerOnce="true">

      <Image  src={config.ImageUrl+"2021.png"} fluid alt={ config.SITE_NAME} />
      <h3>2021</h3>
      <p>Working with 12+ MNC clients and associated with 20+ OEM partners. </p>
      </Fade>

    </li>
     
  </ul>


                </Col>
                </Row>
                </Container>
</section>



      
        </>

    );
}
    }
export default AboutDiv;