import React, { Component ,useEffect }  from 'react';
import props from 'prop-types';

import Footer from '../components/layout/footer';
import Header from '../components/layout/header';
import AboutHome from '../components/section/about_home';
import CardSec from '../components/section/cards';
import Goal from '../components/section/goal';
import Service from '../components/section/service';
import Slider from '../components/section/slider';
import Owlslider from '../components/section/owlslider';
import AboutUs from '../components/section/about_us';
import BookingForm from '../components/section/booking_form';
import config from '../constant';
import ProductsPage from '../components/section/products';
import Container  from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image'
 import { Table } from 'react-bootstrap';
import SubHeader from '../components/layout/subheader';
import {Fade} from 'react-awesome-reveal';

 class Nafta extends Component {

    componentDidMount(){
        document.title = "Nafta oil | " + config.SITE_NAME;
      }

    render(){

    return(
        <div>
        <Header  />   
        <SubHeader  Title='Nafta oil' subtitle='' BkImg={config.ImageUrl + "7-Nafta-oil-Banner.jpg"} /> 

        <Container className='AboutSec py-3'  >

        
<Row className='py-5'>



    <Col lg="12" className=' '>

    <p> Nafta oil, also known as petroleum naphtha, is a versatile hydrocarbon solvent. It finds applications in industries such as petrochemicals, paints, and coatings. </p>
   <p>We offer Nafta Oil as part of our comprehensive marine fuel supply and service solutions. Nafta oil is a light hydrocarbon mixture primarily used as a feedstock in the petrochemical industry for the production of various chemicals and polymers. Nafta oil provides ships with these vital chemical products. Nafta oil serves as a feedstock in various industrial processes.</p>
<p>As part of our diverse range of marine fuel supply services, Nafta oil is a versatile hydrocarbon mixture used in various industrial processes.</p>


       
    </Col>

  
   
</Row>



</Container>
     
        <Footer/>
        </div>
    );
} 
}
export default Nafta;