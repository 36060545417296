import React, { Component, useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
 import Form from 'react-bootstrap/Form';
import config from '../../constant';
 import FloatingLabel from 'react-bootstrap/FloatingLabel';
import SubHeader from '../layout/subheader';
import { Image } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GoogleMap } from '@react-google-maps/api';
import Googlemap from './googlemap';
import {Fade} from 'react-awesome-reveal'

    function validate(evt) {
        var theEvent = evt || window.event;
        var key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode( key );
        var regex = /[0-9]|\./;
        if( !regex.test(key) ) {
          theEvent.returnValue = false;
          if(theEvent.preventDefault) theEvent.preventDefault();
        }
    }

  
      const HomeForm = () => {
 
       
        const [double, setDouble] = useState(false);


        const [mailerState, setMailerState] = useState({
          name: "",
          phone:"",
          email: "",
          message: "",
        });
    
        function handleStateChange(e) {
          setMailerState((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
          }));
        }
    
        const submitEmail = async (e) => {

           e.preventDefault();
           setDouble(true);

          //console.log({ mailerState });
          const response = await fetch(config.BASE_URL+"sendpetrol/", {
            
            method: "POST",
            headers: {
              "Content-type": "application/json"
            },
            body: JSON.stringify({ mailerState }),
          })
            .then((res) => res.json())
            .then(async (res) => {
              const resData = await res;
              console.log(resData);
              if (resData.status === "success") {
                //alert("Message Sent");
                toast.success("Message Sent!" , {autoClose: 2000})

              } else if (resData.status === "fail") {
                //alert("Message failed to send");
                toast.success("Message failed to send!", {autoClose: 2000})

              }
            })
            .then(() => {
              setMailerState({
                email: "",
                name: "",
                phone:"",
                message: "",
              });
              setDouble(false);

 
            });
            
        };
    
         return(
          <>
 
            <div className='pt-4'>
             <Container className="py-5 HomeForm">
                <h4>For enquiry contact us</h4>
             <ToastContainer />

                        <Form onSubmit={submitEmail} >
                <Row>
                    <Col lg="3">
                             <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                 
                                <Form.Control type="text" required placeholder="Name " onChange={handleStateChange}
 name="name"  value={mailerState.name}   />
                            </Form.Group>
                            </Col>
                            <Col lg="3">

                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                 <Form.Control type="text" required maxLength={10} minLength={10} onKeyPress={validate} onChange={handleStateChange}
 name="phone"  value={mailerState.phone} placeholder="88888 55555" />
                            </Form.Group>
                            </Col>
                            <Col lg="3">

                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Control type="email" required placeholder="name@example.com" onChange={handleStateChange}
           name="email"
           value={mailerState.email} />
                            </Form.Group>
                            </Col>
                            <Col lg="3">

                            <Form.Control type="hidden"      onChange={handleStateChange}
 name="message"  value={mailerState.message}  />
                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                            <button type="submit" className='btn btn-warning'  disabled={double}  >    Submit

    </button>
    </Form.Group>

    </Col>

 
                     
                </Row>
                </Form>

            </Container>
            </div> 

            <Container>
            {/* <hr></hr> */}

            </Container>
            <section  className='companyProgress' style={{ 
      backgroundImage: `url( ${config.ImageUrl+"Paralex.jpg"} )`  }}>
        <Container>
            <Row className='text-center'>

                <Col lg="4" >
                <Fade direction='up'  delay="40" triggerOnce="true">
                    <Image src={config.ImageUrl+"1-Employee.png"} />
                    <h1>30+</h1>
                    <p>employees</p>
                    </Fade>
                </Col>

                <Col lg="4">
                <Fade direction='up'  delay="80" triggerOnce="true">
                <Image src={config.ImageUrl+"2-Project-Executed.png"} />

                <h1>20+</h1>
                    <p>Project Executed</p>
                    </Fade>

                </Col>
                <Col lg="4">
                <Fade direction='up'  delay="120" triggerOnce="true">
                <Image src={config.ImageUrl+"3-OEM.png"} />

                <h1>10+</h1>
                    <p>years of experience</p>
                    </Fade>

                </Col>

               
            </Row>
        </Container>
    </section>
            <Container  className="py-5">
            <Row>
            <Col lg="12">
            <h5>Testimonials</h5>
            <h1 className="py-2">What our <br></br> customer say</h1>

           <div className='testiText'> Excellent team of professionals. Best witnessed after sales and customer service 
           </div> 
           </Col>

            </Row>
           <Row className='py-3 reasonsSec'>
            <Col lg="12">
                 
                    <Image  src={config.ImageUrl+"Client.png"} fluid alt={ config.SITE_NAME} />
                    <div className='text'>
                     <h4>Imran Cutlerywala</h4>
                    <p>Founder, BICads</p>
                    </div>
             
            </Col>
            </Row>
            </Container>

            <section className='homeMap'>
              <Container fluid >
                <Row>
                <Col lg="9" className='px-0'>
                  <Googlemap/>
                </Col>
                <Col lg="3">
                <h4 className="pt-3">Contact Us</h4>


                <p> <b> <i className="fa fa-building"></i> </b> <span>{config.address}</span></p>
 

<p><i className="fa fa-envelope"></i> <span> <a href={"mailto:" + config.email_id}>{config.email_id} </a>  </span></p>



<p><i className="fa fa-phone"></i> <span> <a href={"tel:" + config.contact1}> {config.contact1}</a> </span></p>
                </Col>
                </Row>
                
              </Container>
            </section>

            <Container className='py-5 HomeForm text-center'>
              <h1 class="pb-4">Certification</h1>
              <Row>
                <Col lg="4"> <Image src={config.ImageUrl+"certificate-1.jpg"} fluid/>
                  <h4>ISO/IEC 14001:2015</h4>
                </Col>
                <Col  lg="4">   <Image src={config.ImageUrl+"certificate-2.jpg"} fluid/>
                <h4>ISO/IEC 27001:2013</h4>

                </Col>
                <Col  lg="4">    <Image src={config.ImageUrl+"certificate-3.jpg"} fluid/>
                <h4>ISO 22301</h4>

                </Col>
              </Row>
            </Container>
 
            </>

            
         )
    }


export default HomeForm;