import logo from './logo.svg';
import './App.css';
import {BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './pages/home';
import ErrorPage from './pages/404';
import About from './pages/about';
import Contact from './pages/contact';
 
import ProductsMain from './pages/productsMain';
 
import ScrollToTop from './ScrollToTop';
 
import Methanol from './pages/methanol_lpg';
import Nafta from './pages/nafta_oil';
import Bitumen from './pages/bitumen';
import Base_oils from './pages/base_oils';
import Fuel_oils from './pages/fuel_oil';
import MarineGas from './pages/marine_gas_oil';
import LowHigh from './pages/Low_high_sulfer_gasoil';
import Crude_oil from './pages/crude_oil_light_heavy';

function App() {
  return (
   <BrowserRouter>
      <ScrollToTop />

    <Routes>
      <Route path="/" element= {<Home />} />
      <Route path="/home" element= {<Home />} />
      <Route path="/contact" element= {<Contact />} />
      <Route path="/about" element= {<About/>} />
      <Route path="/methanol_lpg" element= {<Methanol/>} />
      <Route path="/nafta_oil" element= {<Nafta/>} />
      <Route path="/bitumen" element= {<Bitumen/>} />
      <Route path="/base_oils" element= {<Base_oils/>} />
      <Route path="/marine_gas_oil" element= {<MarineGas/>} />
      <Route path="/low_high_sulfer_gasoil" element= {<LowHigh/>} />
      <Route path="/crude_oil_light_heavy" element= {<Crude_oil/>} />
      <Route path="/fuel_oil" element= {<Fuel_oils/>} />
          <Route path="/products" element= {<ProductsMain/>} />
     
        <Route path="*" element={<ErrorPage />} />

    </Routes>

   </BrowserRouter>
  );
}

export default App;
