import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Image from 'react-bootstrap/Image'

import { images } from './service'; 
import { NavLink } from 'react-router-dom';
import config from '../../constant';

const options = {
    margin: 30,
    responsiveClass: true,
    nav: true,
    dots: false,
    autoplay: true,
    navText: ['<img src="assets/images/petrol/arrow.png">', '<img src="assets/images/petrol/arrow-2.png">'],
    smartSpeed: 1000,
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items: 1,
        },
        600: {
            items: 2,
        },
        700: {
            items: 2,
        },
        1000: {
            items: 3,

        }
    },
};
    class Owlslider extends Component {
    
    render() {
    return(
        <div className='AboutBg py-3' style={{ 
            backgroundImage: `url( ${config.ImageUrl + "BG-factory-silhoutee.png"} )`   }}>
        <Container className="py-5">
            <h5>Our services</h5>
            <h1 className="py-2">What we do</h1>

            <p>FLC trading is a leading international provider of marine fuel supply and related service solutions to ships in port and at sea. Our experience and our most competitive prices make our company the most reliable partner. All our barges are sire approved </p>

            <Row className="justify-content-center text-center">
            {/* <Col xs lg="6">1 of 2</Col> */}
            <Col xs lg="12">
        <OwlCarousel className='owl-theme'  {...options}>
        
        {images.map( (val , i) => (  
               
    <div className='item' key={i}>
    {/* <Image src={val.imgUrl} alt={`${val.imgCaption}`} fluid />    */}

<div className="productDiv" style={{ 
      backgroundImage: `url( ${val.imgUrl} )` 
    }} >
					       
				         </div>

                         <div className="captionDiv">
						    <h3>{`${val.imgCaption}`} </h3>
                           <NavLink to={`${val.url}`}> <p><i className='fa fa-angle-right'></i> Find More</p></NavLink>
 					     </div>
                         </div>
                         )  )}

</OwlCarousel>
</Col>
</Row>
</Container>
</div>
    );
}

}



export default Owlslider;