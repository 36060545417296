import React ,{ Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'
import config from '../../constant';
import { NavLink } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Ratio from 'react-bootstrap/Ratio';
import {Fade} from 'react-awesome-reveal';
import { Button } from 'react-bootstrap';

class AboutHome extends Component {
        render() {

    return(
        <>

        <section className='connectSection'  >
    <Container className='AboutSec '  >

        
        {/* <Row className='d-none'>
            <Col lg="6" className='px-0' style={{ 
      backgroundImage: `url( ${config.ImageUrl + "About-us.jpg"} )`   }} >
           
            </Col>
            <Col lg="6" className='px-0 '>

            <div className='conncetDiv container'>
            <Fade direction='right'  delay="40" triggerOnce="true">
             <h5>Our services</h5>
            <h1 className="py-2">Connecting <br></br> things</h1>

            <p>Lord Petroleum LLC has been setup in Abu Dhabi (UAE) to establish a long term presence in the Middle East in terms of bunkering. We can provide with very short notice, Crude oil light & heavy, CST 100 & 180 & 280 & 380, Nafta, D2-D6, Jet Fuel, Methanol, Gasoline, Gas oil, Fuel Oil.  </p>

           <NavLink to="/about"><Button className='btn btn-primary' type='button'>Read More</Button></NavLink> 
                </Fade>
            </div>
            </Col>
        </Row> */}

        <Row>
            <Col  lg="5" className='py-5'>
                {/* <Image src={config.ImageUrl+"About-us2.jpg"} fluid/> */}
                <div className='image-container'>
      <div className='image-holder' style={{ 
      backgroundImage: `url( ${config.ImageUrl + "Conneting-Things.jpg"} )`   }}>
      </div>
      {/* <div className='labelText'>
        Image Transition and Transform on Scroll
      </div> */}
    </div>
            </Col>
            <Col  lg="7">
            <div className='conncetDiv container'>
            <Fade direction='right'  delay="40" triggerOnce="true">
             <h5>Our services</h5>
            <h1 className="py-2">Connecting <br></br> things</h1>

            <p>Lord Petroleum has been setup in Abu Dhabi (UAE) to establish a long term presence in the Middle East in terms of bunkering. We can provide with very short notice, Crude oil light & heavy, CST 100 & 180 & 280 & 380, Nafta, D2-D6, Jet Fuel, Methanol, Gasoline, Gas oil, Fuel Oil.  </p>

           <NavLink to="/about"><p><i className='fa fa-angle-right'></i> Find More</p></NavLink> 
                </Fade>
                </div>

            </Col>
        </Row>
    </Container>
    </section>

 
        </>

    );
}
    }
export default AboutHome;