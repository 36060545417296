import React, { Component ,useEffect }  from 'react';
import props from 'prop-types';

import Footer from '../components/layout/footer';
import Header from '../components/layout/header';
import AboutHome from '../components/section/about_home';
import CardSec from '../components/section/cards';
import Goal from '../components/section/goal';
import Service from '../components/section/service';
import Slider from '../components/section/slider';
import Owlslider from '../components/section/owlslider';
import AboutUs from '../components/section/about_us';
import BookingForm from '../components/section/booking_form';
import config from '../constant';
import ProductsPage from '../components/section/products';
import Container  from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image'
 import { Table } from 'react-bootstrap';
import SubHeader from '../components/layout/subheader';
import {Fade} from 'react-awesome-reveal';

 class Bitumen extends Component {

    componentDidMount(){
        document.title = "Bitumen (60/70 and 80/100) | " + config.SITE_NAME;
      }

    render(){

    return(
        <div>
        <Header  />   
        <SubHeader  Title='Bitumen (60/70 and 80/100)' subtitle='' BkImg={config.ImageUrl + "6-Bitumen-Banner.jpg"} /> 

        <Container className='AboutSec py-3'  >

        
<Row className='py-5'>



    <Col lg="12" className=' '>

   <p> Our bitumen services encompass the supply of 60/70 and 80/100 grade bitumen. Bitumen is widely used in the maritime industry for applications such as road construction, waterproofing, and pavement maintenance.</p>
<p>We provide bitumen solutions for various maritime construction and maintenance projects. Our bitumen options include 60/70 and 80/100 grades, allowing for versatile applications such as road paving, waterproofing, and roofing. With our high-quality bitumen, ships can rely on durable and long-lasting infrastructure.</p>
<p>Bitumen is a crucial component in the construction and maintenance of maritime infrastructure, including roads, ports, and terminals. We provide high-quality bitumen in different grades, such as 60/70 and 80/100, to meet the specific requirements of our clients. These grades represent the penetration range of the bitumen and determine its viscosity and hardness properties. Our bitumen supply ensures that maritime infrastructure projects receive the necessary materials for their successful completion, contributing to the efficiency and durability of port facilities.</p>



       
    </Col>

    
   
</Row>



</Container>
     
        <Footer/>
        </div>
    );
} 
}
export default Bitumen;