import React, { Component ,useEffect }  from 'react';
import props from 'prop-types';

import Footer from '../components/layout/footer';
import Header from '../components/layout/header';
import AboutHome from '../components/section/about_home';
import CardSec from '../components/section/cards';
import Goal from '../components/section/goal';
import Service from '../components/section/service';
import Slider from '../components/section/slider';
import Owlslider from '../components/section/owlslider';
import AboutUs from '../components/section/about_us';
import BookingForm from '../components/section/booking_form';
import config from '../constant';
import ProductsPage from '../components/section/products';
import Container  from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image'
 import { Table } from 'react-bootstrap';
import SubHeader from '../components/layout/subheader';
import {Fade} from 'react-awesome-reveal';

 class Fuel_oils extends Component {

    componentDidMount(){
        document.title = "Fuel Oil 100, 180, 280, and 380 CST | " + config.SITE_NAME;
      }

    render(){

    return(
        <div>
        <Header  />   
        <SubHeader  Title='Fuel Oil 100, 180, 280, and 380 CST' subtitle='' BkImg={config.ImageUrl + "4-Fueloil-banner.jpg"} /> 

        <Container className='AboutSec py-3'  >

        
<Row className='py-5'>



    <Col lg="12" className=' '>
   


   <p> We supply a range of fuel oil grades, including 100, 180, 280, and 380 CST, which cater to the specific needs of different marine vessels. Our fuel oil products offer consistent quality and reliable performance, ensuring smooth operations at sea. We provide reliable and efficient fuelling solutions, ensuring smooth operations for ships of all types and sizes.</p>
   <p>
Fuel oil is a heavy fuel commonly used in maritime transportation. We provide a range of fuel oil options with varying viscosities, including Fuel Oil 100, 180, 280, and 380 cst, catering to different vessel requirements. These fuel oils are ideal for powering large marine engines and boilers, ensuring reliable and consistent performance. Our commitment to quality ensures that our fuel oil supply adheres to industry standards, enabling seamless operations for ships in port and at sea.</p>

       
    </Col>

    
   
</Row>



</Container>
     
        <Footer/>
        </div>
    );
} 
}
export default Fuel_oils;