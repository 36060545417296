import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import config from '../../constant';
 
export const images = [
    {
        imgUrl: config.ImageUrl+'1-Crude-Oil.jpg',
        imgCaption:'Crude oil light & heavy',
        url:'/crude_oil_light_heavy'
    },
    {
        imgUrl: config.ImageUrl+'2-Sulfur-Gasoil.jpg',
        imgCaption:'low and high sulfer gasoil',
        url:'/low_high_sulfer_gasoil'

    },
    {
        imgUrl: config.ImageUrl+'3-Marine-Gasoil.jpg',
        imgCaption:'marine gas oil (dma)',
        url:'/marine_gas_oil'
   },
    {
        imgUrl: config.ImageUrl+'4-Fuel-Oil.jpg',
        imgCaption:'Fuel oil 100,180,280 and 380 cst',
        url:'/fuel_oil'
   },
    {
        imgUrl: config.ImageUrl+'5-Base-Oil.jpg',
        imgCaption:'Base oils (sn150, sn500 and bright stocks)',
        url:'/base_oils'
   },
    {
        imgUrl:config.ImageUrl+'6-Bitumen.jpg',
        imgCaption:'Bitumen (60 / 70 and 80 / 100)',
        url:'/bitumen'
   },
    {
        imgUrl: config.ImageUrl+'7-Nafta.jpg',
        imgCaption:'Nafta oil',
        url:'/nafta_oil'
   },
    {
        imgUrl:config.ImageUrl+'8-LPG.jpg',
        imgCaption:'Methanol and lpg',
        url:'/methanol_lpg'
   },
    
  
    
    
    
    

]
class Service extends Component {

   
    render(){
        return(
            <div>
              <Container className="pb-3">
            <h1 className="py-2">Speciality</h1>
            <Row className="justify-content-center text-center">

                        {images.map( (val , i) => (     
                        <Col lg="4" key={i}>   
                         <div className="productDiv" style={{ 
      backgroundImage: `url( ${ val.imgUrl} )` 
    }} >
					        <div className="captionDiv">
						    <h3>{`${val.imgCaption}`} </h3>
						    <span className="plus"><a href="https://www.jadefinance.org/green_climate_funding">+</a></span>
					     </div>
				         </div>
                         </Col>
                         )  )}


                         
                       
                     </Row>
                </Container>
            </div>
        )
    }
}

export default Service;