import React, { Component ,useEffect }  from 'react';
import props from 'prop-types';

import Footer from '../components/layout/footer';
import Header from '../components/layout/header';
import AboutHome from '../components/section/about_home';
import CardSec from '../components/section/cards';
import Goal from '../components/section/goal';
import Service from '../components/section/service';
import Slider from '../components/section/slider';
import Owlslider from '../components/section/owlslider';
import AboutUs from '../components/section/about_us';
import BookingForm from '../components/section/booking_form';
import config from '../constant';
import ProductsPage from '../components/section/products';
import Container  from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image'
 import { Table } from 'react-bootstrap';
import SubHeader from '../components/layout/subheader';
import {Fade} from 'react-awesome-reveal';

 class LowHigh extends Component {

    componentDidMount(){
        document.title = "Low and High Sulfur Gasoil | " + config.SITE_NAME;
      }

    render(){

    return(
        <div>
        <Header  />   
        <SubHeader  Title='Low and High Sulfur Gasoil' subtitle='' BkImg={config.ImageUrl + "2-Sulfur-Gasoil-Banner.jpg"} /> 

        <Container className='AboutSec py-3'  >

        
<Row className='py-5'>



    <Col lg="12" className=' '>

   <p>    
Our low and high sulfur gasoil offerings cater to different industry requirements. We maintain strict quality control measures to provide you with compliant and reliable gasoil products.</p>
   <p>In compliance with environmental regulations and industry standards, we provide low and high sulfur gasoil options for marine fuel supply. Low sulfur gasoil contains significantly reduced sulfur content, aiding in the reduction of harmful emissions, including sulfur oxides (SOx). High sulfur gasoil is suitable for vessels equipped with emission abatement technologies or those operating in regions where such regulations are less stringent. Our comprehensive range of gasoil ensures that ships can comply with environmental requirements without compromising their operational efficiency.
</p>
<p>We understand the importance of environmental sustainability in the maritime industry. That's why we offer low sulfur gasoil, complying with international regulations and reducing emissions. Additionally, we provide high sulfur gasoil for vessels that require specific fuel compositions for their operations.
</p>

 


       
    </Col>

    
   
</Row>



</Container>
     
        <Footer/>
        </div>
    );
} 
}
export default LowHigh;