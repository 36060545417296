import React, { Component ,useEffect }  from 'react';
import props from 'prop-types';

import Footer from '../components/layout/footer';
import Header from '../components/layout/header';
import AboutHome from '../components/section/about_home';
import CardSec from '../components/section/cards';
import Goal from '../components/section/goal';
import Service from '../components/section/service';
import Slider from '../components/section/slider';
import Owlslider from '../components/section/owlslider';
import AboutUs from '../components/section/about_us';
import BookingForm from '../components/section/booking_form';
import config from '../constant';
import ProductsPage from '../components/section/products';
import Container  from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image'
 import { Table } from 'react-bootstrap';
import SubHeader from '../components/layout/subheader';
import {Fade} from 'react-awesome-reveal';

 class MarineGas extends Component {

    componentDidMount(){
        document.title = "Marine Gas Oil (DMA) | " + config.SITE_NAME;
      }

    render(){

    return(
        <div>
        <Header  />   
        <SubHeader  Title='Marine Gas Oil (DMA)' subtitle='' BkImg={config.ImageUrl + "3-Marine-Gasoil-Banner.jpg"} /> 

        <Container className='AboutSec py-3'  >

        
<Row className='py-5'>



    <Col lg="12" className=' '>

   <p>  Marine Gas Oil, also known as DMA (Distillate Marine Fuel), is a high-quality fuel specifically designed for marine engines. This high-quality fuel ensures optimal performance and meets the stringent requirements of the maritime industry. It is a low-viscosity fuel that offers excellent combustion characteristics and efficient energy output. DMA meets stringent quality standards and is suitable for a wide range of marine applications, including auxiliary engines, generators, and boilers. With our reliable supply of Marine Gas Oil, ships can operate efficiently and minimize their environmental impact. DMA service ensures a clean and efficient fuel supply for ships.</p>
   <p>Our marine gas oil (DMA) is specifically formulated for marine engines, providing excellent performance and efficiency. It is a low viscosity fuel that offers reliable power while minimizing engine maintenance requirements. With our marine gas oil, ships can navigate the seas smoothly and effectively.
</p>


   

       
    </Col>

    
   
</Row>



</Container>
     
        <Footer/>
        </div>
    );
} 
}
export default MarineGas;